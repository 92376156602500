import {
  Badge,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Heading,
  Highlight,
  Modal,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useDisclosure,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import ActionButton from "@components/atom/ActionButton";
import { NO_DISPATCH_SERVICE_TYPE, X_DISTANCE } from "@config/constant";
import { usePayment, usePosPayment } from "@hooks/api/usePayment";
import { Icon } from "@iconify/react";
import usePaymentAction from "@store/action-hook/usePaymentAction";
import { MIN_PRICE_PERCENTAGE } from "@store/constant.store";
import { useRedStore } from "@store/store";
import { calculatePercentage, convertToCurrency, getValueFromURLParams } from "@utils";
import { debounce } from "lodash";
import { memo, useEffect } from "react";
import { CircularSliderWithChildren } from "react-circular-slider-svg";
import { useNavigate, useParams } from "react-router-dom";
import boopSf from "/assets/sound/success-pay.mp3";
// @ts-ignore
import useSound from "use-sound";
import { useOrder } from "@hooks/api/useSave";
import RedMargin from "@components/molecules/RedMargin";
import RedMarginPricingPage from "@components/molecules/RedMarginPricingPage";
import { pageView, paymentClickCollectCash, paymentClickOnCollectOnline, paymentsAdvanceAmountClick, paymentsTotalAmountClick } from "@config/event";

import PosInitiatePayment from "@features/pos/pos-initiate-payment";

const PaymentInfoCard = memo(() => {
  const [play] = useSound(boopSf);
  const { id } = useParams();
  const navigate = useNavigate();
  const { advanceAmount, amount, progress, minAmount, selectedMode, totalAmount, isFullyPaid } = useRedStore(state => state.collectPayment);
  const { __updatePaymentAmount, __handleAdvanceSelectMode, __handleTotalSelectMode, __handleAdvanceAmountChange } = usePaymentAction();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { collectCashFn, collectCashAsyncState } = usePayment();

  const order = useRedStore(state => state.order);
  const is_is_more_then_x_km = useRedStore(state => state.localOrderChanges?._is_more_then_x_km);

  const buttonColorValue = useColorModeValue("#151f21", "gray.900");
  const isAdvance = selectedMode === "advance";
  const isTotal = selectedMode === "total";
  const isPriceMandatory = useRedStore(state => state.collectPayment.isPriceMandatory);

  const isSaathiFleet = order?.orderStatus === "payment" && order?.isSaathiFleet && amount === totalAmount;
  const isDisable = isAdvance ? (!isPriceMandatory ? advanceAmount >= minAmount : advanceAmount > 0) && totalAmount >= advanceAmount : !!totalAmount;
  const mobile = order.requestedFor?.mobile || order.mobile || order.requestedBy.mobile;

  const onSliderChange = debounce((v: number) => {
    const _selectedMode = totalAmount > advanceAmount ? "advance" : "total";
    const float = parseInt(v?.toFixed(0));
    const _advanceAmount = Math.floor(calculatePercentage(totalAmount, float));
    const payload = {
      progress: float,
      advanceAmount: _advanceAmount,
      selectedMode: _selectedMode,
      amount: _advanceAmount,
    };

    __updatePaymentAmount(payload);
  }, 10);

  const onCollectPayment = () => {
    id &&
      paymentClickOnCollectOnline({
        orderId: id,
        amount: amount,
        totalAmount: totalAmount,
        advanceAmount: advanceAmount,
        isPriceMandatory: isPriceMandatory,
      });
    let url = `/case/${id}/payment/collect-online?amount=${amount * 100}&mobile=${mobile}`;
    if (isSaathiFleet) {
      url = url.concat("&isSaathiFleet=true");
    }
    navigate(url);
  };

  const handleCashCollect = () => {
    id &&
      paymentClickCollectCash({
        orderId: id,
        amount: amount,
        totalAmount: totalAmount,
        advanceAmount: advanceAmount,
        isPriceMandatory: isPriceMandatory,
      });
    collectCashFn({
      isSaathiFleet: !!isSaathiFleet,
      onSuccess: handleCollectCashCb,
    });
  };

  function handleCollectCashCb() {
    // play sound:
    play();

    // 2. Navigate to Case history page
    // 3. Navigate to dispatch page.
    const historyPage = (id: string) => `/case-history/${id}/case-overview`;
    const dispatchPage = (id: string) => `/case/${id}/ready-to-dispatch`;
    const navigateTo = getValueFromURLParams("onSuccessNavigate"); // From History page.

    if (navigateTo) {
      navigate(navigateTo);
    } else if (NO_DISPATCH_SERVICE_TYPE.includes(order.serviceType) || is_is_more_then_x_km) {
      const url = historyPage(order?.orderId);
      navigate(url);
    } else {
      const url = dispatchPage(order?.orderId);
      navigate(url);
    }
  }

  // [feature-saathi-pricing]
  const partnerCost = getValueFromURLParams("partnerCost");
  const redMargin = getValueFromURLParams("redMargin");

  useEffect(() => {
    pageView("Payment Page", {
      orderId: id,
      totalAmount: totalAmount,
      isPriceMandatory: isPriceMandatory,
      advanceAmount: advanceAmount,
    });
  }, []);

  const handleAdvanceSelectionMode = () => {
    paymentsAdvanceAmountClick({
      orderId: id,
      advanceAmount: advanceAmount,
      isPriceMandatory: isPriceMandatory,
    });
    __handleAdvanceSelectMode(isPriceMandatory);
  };

  const handleTotalSelectionMode = () => {
    paymentsTotalAmountClick({
      orderId: id,
      totalAmount: totalAmount,
      isPriceMandatory: isPriceMandatory,
    });
    __handleTotalSelectMode();
  };

  return isFullyPaid ? (
    <Center h={"60vh"} as={Stack} p={4}>
      <Icon icon={"ep:success-filled"} color="green" fontSize={50} />
      <Text fontSize={"sm"} fontWeight={"semibold"} color={"gray.500"}>
        No Pending Amount to Collect
      </Text>
      <Button
        px={8}
        mx={10}
        mt={10}
        bg={buttonColorValue}
        color={"white"}
        rounded={"md"}
        _hover={{
          transform: "translateY(-2px)",
          boxShadow: "lg",
        }}
        onClick={() => navigate(`/case-history/${id}/case-overview`)}
        isLoading={collectCashAsyncState.isLoading}
        fontSize={"sm"}
        fontWeight={"semibold"}
      >
        Go To Case History
      </Button>
    </Center>
  ) : (
    <Box my={5} borderRadius={"md"} padding={4}>
      <Center flexDirection={"column"}>
        <Stack w={"full"} justify={"center"} align={"center"}>
          <CircularSliderWithChildren
            size={300}
            trackWidth={14}
            disabled={!totalAmount || amount < 0}
            minValue={!isPriceMandatory ? MIN_PRICE_PERCENTAGE : 0}
            arcBackgroundColor="#D4D2F7"
            handle1={{
              value: progress,
              onChange: v => {
                onSliderChange(v);
              },
            }}
            arcColor="#6363DD"
            startAngle={40}
            endAngle={320}
          >
            <Center h={"32"} as={Stack} pos={"relative"}>
              <Text fontSize={"s,"}>Amount</Text>
              <Heading fontSize={"4xl"} fontWeight={"semibold"}>
                <span style={{ fontSize: 24, marginRight: 4 }}>₹</span>
                {convertToCurrency(amount || 0, true)}
              </Heading>
              <Text fontSize={"sm"}>Out of {convertToCurrency(totalAmount)}</Text>
              <Badge pos={"absolute"} bottom={-16} fontSize={"sm"} colorScheme={progress > 50 ? (progress > 75 ? "green" : "blue") : "red"} px={4} py={1} borderRadius={"full"}>
                {/* "😃 Good" : "🥳 Great"} */}
                {progress < 50 && "🙂 Ok"}
                {progress > 50 && progress < 75 && "😃 Good"}
                {progress > 75 && "🥳 Great"}
              </Badge>
            </Center>
          </CircularSliderWithChildren>

          <Stack w={"full"}>
            <Flex
              justify={"space-between"}
              align={"center"}
              bg={isTotal ? "purple.100" : "white"}
              borderColor={isTotal ? "purple.400" : "gray.300"}
              borderWidth={1}
              px={3}
              py={2}
              borderRadius={"md"}
              shadow={"sm"}
              minH={16}
              onClick={handleTotalSelectionMode}
            >
              <Box borderRadius={"md"}>
                <Text fontSize={"md"} letterSpacing={"wide"} fontWeight={"semibold"} color={"gray.800"}>
                  Total Amount
                </Text>
                <Text fontWeight={"normal"} fontSize={"xs"} color={"gray.500"}>
                  Total Outstanding amount.
                </Text>
              </Box>
              <Heading fontSize={"xl"}>{convertToCurrency(totalAmount)}</Heading>
            </Flex>

            <Flex
              mt={4}
              justify={"space-between"}
              align={"center"}
              borderWidth={1}
              px={3}
              py={2}
              borderRadius={"md"}
              shadow={"sm"}
              minH={16}
              bg={isAdvance ? "purple.100" : "white"}
              borderColor={isAdvance ? "purple.400" : "gray.300"}
              onClick={handleAdvanceSelectionMode}
            >
              <Box borderRadius={"md"}>
                <Text fontSize={"md"} letterSpacing={"wide"} fontWeight={"semibold"} color={"gray.800"}>
                  Advance Amount
                </Text>

                {!isPriceMandatory ? (
                  <Text fontWeight={"normal"} fontSize={"xs"} color={"gray.500"} mr={2}>
                    <Highlight query="20%" styles={{ px: "1", py: "1", bg: "red.100", borderRadius: "md" }}>
                      The Advance amount need to be more than or equal to 20% of the total amount,
                    </Highlight>
                  </Text>
                ) : (
                  <Text fontWeight={"normal"} fontSize={"xs"} color={"gray.500"} mr={2}>
                    <Highlight query="20%" styles={{ px: "1", py: "1", bg: "red.100", borderRadius: "md" }}>
                      The Advance amount need to be more than 0% of the total amount
                    </Highlight>
                  </Text>
                )}
              </Box>
              {/* <Heading fontSize={"xl"}>{convertToCurrency(order.fareAgreed)}</Heading> */}
              <Flex justify={"start"} align={"center"}>
                <Text fontSize={"xl"} fontWeight={"semibold"} mt={0.5}>
                  ₹
                </Text>
                <NumberInput
                  keepWithinRange={true}
                  clampValueOnBlur={false}
                  onChange={(e: any) => __handleAdvanceAmountChange(e, isPriceMandatory)}
                  value={advanceAmount}
                  pl={1}
                  variant={"outline"}
                  borderColor={"blackAlpha.500"}
                >
                  <NumberInputField w={20} textAlign={"right"} max={totalAmount} fontSize={"xl"} fontWeight={"semibold"} value={advanceAmount} color={"black"} pr={1} pl={1} min={0} />
                </NumberInput>
              </Flex>
            </Flex>
            {/* [feature-saathi-pricing] */}
            {partnerCost && redMargin && <RedMarginPricingPage constToOwner={partnerCost} percentage={redMargin} />}
          </Stack>
        </Stack>
        <Stack my={10}>
          <Flex justify={"space-around"} gap={0.5}>
            <ActionButton isDisabled={!isDisable} leftIcon={<Icon icon="fluent:payment-32-regular" fontSize={20} />} label="Collect Online" onClick={onCollectPayment} />
            {id && amount && order?.originSource?.source && <PosInitiatePayment orderId={id} isDisable={!isDisable} amount={amount} sourceId={order?.originSource?.source} />}
          </Flex>
          <Button isDisabled={!isDisable} mt={-6} variant={"link"} textDecoration={"underline"} fontWeight={"semibold"} colorScheme="blue" onClick={onOpen}>
            Collect Cash
          </Button>
        </Stack>
      </Center>

      <Drawer isOpen={isOpen} placement="bottom" onClose={() => !collectCashAsyncState.isLoading && onClose()}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={10}>
            <Center>
              <Stack spacing={4} justify={"space-between"} align={"center"} minH={"25vh"}>
                <Center flexDir={"column"} textAlign={"center"}>
                  <Text fontSize={"5xl"} fontWeight={"semibold"} color={"black"}>
                    {convertToCurrency(amount)}
                  </Text>
                  <Text fontSize={"sm"} fontWeight={"normal"}>
                    You are collecting the payment in cash. Click below to confirm and proceed.
                  </Text>
                </Center>
                <Button
                  px={8}
                  bg={buttonColorValue}
                  color={"white"}
                  rounded={"md"}
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "lg",
                  }}
                  onClick={handleCashCollect}
                  w={"full"}
                  isLoading={collectCashAsyncState.isLoading}
                  isDisabled={!isDisable}
                >
                  Collect Cash
                </Button>
              </Stack>
            </Center>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
});

export default PaymentInfoCard;
