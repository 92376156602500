import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { usePosPayment, usePosPaymentStatus } from "@hooks/api/usePayment";
import { convertTo, showErrorToast, showSuccessToast, showSuccessToastV2 } from "@utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { Drawer, DrawerBody, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import PosPaymentInitiateModalContent from "@features/pos/components/pos-payment-initiate-modal-content";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import ActionButton from "@components/atom/ActionButton";
import { IPosInitiatePayment, TPosPaymentMode, TPosPaymentStatus } from "@features/pos/config/types";
import usePos from "@features/pos/hooks/usePos";
import { FeatureLoader } from "@components/wrapper/NetworkWrapper";
import { useAppStore } from "@store/AppStore";

export default function PosInitiatePayment({ isDisable, orderId, amount, sourceId }: IPosInitiatePayment) {
  const { ...rest } = usePos({});
  const navigate = useNavigate();
  const loginUser = useAppStore(state => state.loginUser)
  const { posPayment, posPaymentStatus } = usePosPayment({ orderId, onError: handlePaymentInitiatedFailure, sourceId, userId: loginUser?.userId || '' });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  const paymentStatus = usePosPaymentStatus({ orderId, sourceId: sourceId });

  const _handleSuccess = useCallback(
    (toastMessage?: string) => {
      showSuccessToastV2(toastMessage || "POS Payment initiated.");

      if (amount <= 0) {
        showErrorToast("Amount should be greater than 0.");
        return;
      }

      if (!sourceId) {
        showErrorToast("Source Id is required.");
        return;
      }
      const url = `pos-status?amount=${convertTo(amount)}&sourceId=${sourceId}`;
      navigate(url);
    },
    [amount, navigate, sourceId]
  );

  function handlePaymentInitiatedFailure(err: any) {
    const errorBody = (err as any)?.response?.data?.message;
    setErrorMessage(errorBody);
    // onClose();
  }

  const handleAgree = useCallback(
    (paymentMode: TPosPaymentMode) => {
      if (orderId && !isNaN(amount)) {
        const paisa = +amount * 100;
        posPayment({ amount: paisa, paymentMode }, () => {
          // onOpen();
          _handleSuccess();
        });
      } else {
        showErrorToast("Order Id is required.");
        console.error("Order Id is required");
      }
    },
    [orderId, amount, posPayment, _handleSuccess]
  );

  const handlePosButtonClick = useCallback(() => {
    onOpen();
  }, []);

  const handleClose = () => {
    setErrorMessage("");
    onClose();
  };

  const isPosEnabled = !!paymentStatus?.data?.isPosEnabled;

  const status: TPosPaymentStatus = paymentStatus?.data?.status;
  const hasOnGoingTnx = ["PENDING", "INITIATED"]?.includes(status);

  if (hasOnGoingTnx) {
    _handleSuccess("POS Payment is already initiated.");
  }

  return (
    <FeatureLoader status={rest} isLoadBackground>
      {isPosEnabled ? (
        <>
          <ActionButton
            isLoading={posPaymentStatus?.isLoading}
            isDisabled={isDisable}
            leftIcon={<Icon icon="hugeicons:credit-card-pos" fontSize={20} />}
            label="POS Payment"
            onClick={handlePosButtonClick}
            as={Box}
            backgroundColor={"transparent"}
            variant={"outline"}
            color={"black"}
            ml={2}
          />
          <Drawer isOpen={isOpen} placement="bottom" onClose={handleClose} finalFocusRef={btnRef}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerBody padding={10}>
                {paymentStatus?.data?.transactionId && hasOnGoingTnx && (
                  <Box bg={"blue.100"} p={2} borderRadius={"md"} borderWidth={1} borderColor={"blue.300"} px={4} mx={-5}>
                    <Text fontWeight={"normal"} fontSize={"sm"}>
                      Ongoing transaction ID: <strong> {paymentStatus?.data?.transactionId}</strong>
                    </Text>
                  </Box>
                )}

                {errorMessage && (
                  <Flex color={"red.500"} gap={2} alignItems={"start"} bg={"red.100"} p={2} borderRadius={"md"}>
                    <Box p={2} borderRadius={"full"}>
                      <Icon icon={"bx:error"} fontSize={25} />
                    </Box>{" "}
                    <Text color={"red.600"} textTransform={"capitalize"}>
                      {errorMessage}
                    </Text>
                  </Flex>
                )}
                <PosPaymentInitiateModalContent isDisabled={hasOnGoingTnx} isLoading={posPaymentStatus?.isLoading} onAgree={handleAgree} amount={amount} />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      ) : null}
    </FeatureLoader>
  );
}
