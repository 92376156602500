import { getPosSourceList } from "api/service/mdm";
import React from "react";
import { useQuery } from "react-query";

export default function usePos({ onSuccess }: { onSuccess?: (data: any) => void }) {
  const [posEnabledSource, setPosEnabledSource] = React.useState<string[]>([]);

  const { ...rest } = useQuery(["pos-source-list"], getPosSourceList, {
    select: data => data,
    onSuccess: (data: any) => {
      setPosEnabledSource(data);
      onSuccess && onSuccess(data);
    },
  });

  const isPosEnabledSource = (source: string) => {
    return posEnabledSource.includes(source);
  };

  return { isPosEnabledSource, ...rest };
}
