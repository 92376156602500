import { Button } from "@chakra-ui/react";
import { memo } from "react";

type Props = {
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  text: string;
};

function ActionButton({ onClick, isDisabled, isLoading, text }: Props) {
  return (
    <Button
      px={8}
      bg={"black"}
      color={"white"}
      rounded={"md"}
      _hover={{
        transform: "translateY(-2px)",
        boxShadow: "lg",
      }}
      onClick={onClick}
      w={"full"}
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      {text}
    </Button>
  );
}

export default memo(ActionButton);
