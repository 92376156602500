import { Center, Flex, Stack, Tab, TabList, Tabs, Text } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import { useCallback, useState } from "react";
import PosIcon from "../assets/pos_icon.png";
import bhim from "../assets/bhim.svg";
import bhimSelected from "../assets/bhim-selected.svg";
import ActionButton from "@features/pos/components/buttons/action-button";
import { PAYMENT_MODES } from "@features/pos/config/constant";
import { TPosPaymentMode } from "@features/pos/config/types";
import { convertToCurrency } from "@utils";

type Props = {
  onAgree: (paymentMode: TPosPaymentMode) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  amount?: number;
};

export default function PosPaymentInitiateModalContent({ onAgree, isLoading, isDisabled, amount }: Props) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleSubmit = useCallback(() => {
    onAgree((PAYMENT_MODES[selectedTab] as any) || "all");
  }, [selectedTab, onAgree]);

  return (
    <Center>
      <Stack spacing={4} justify={"space-between"} align={"center"} minH={"25vh"}>
        <Center flexDir={"column"} textAlign={"center"}>
          <img src={PosIcon} alt="cash" height={"auto"} width={150} />
          <Text fontSize={"5xl"} fontWeight={"semibold"} color={"black"}>
            {convertToCurrency(amount || 0)}
          </Text>
          <Tabs variant="soft-rounded" marginY={5} onChange={setSelectedTab}>
            <TabList bg={"blackAlpha.100"} p={1}>
              <Tab flex={1} borderRadius={4} _selected={{ color: "white", bg: "blackAlpha.800", borderRadius: 4 }}>
                <Flex alignItems={"center"} gap={2}>
                  <Icon icon="akar-icons:credit-card" height={20} width={20} />
                  Card
                </Flex>
              </Tab>
              <Tab isDisabled flex={1} borderRadius={4} _selected={{ color: "white", bg: "blackAlpha.800", borderRadius: 4 }}>
                <Flex alignItems={"center"} gap={2}>
                  <img src={selectedTab === 0 ? bhim : bhimSelected} alt="bhim" height={20} width={20} style={{ color: "white" }} />
                  UPI
                </Flex>
              </Tab>
            </TabList>
          </Tabs>
          <Text fontSize={"sm"} fontWeight={"normal"}>
            You are collecting the payment via POS. Click below to confirm and proceed.
          </Text>
        </Center>
        <ActionButton onClick={handleSubmit} isLoading={isLoading} isDisabled={isDisabled} text="Initiate POS Payment" />
      </Stack>
    </Center>
  );
}
